

export const chains = {
    bsc_network_testnet: {
        chainId: "0x61",
        rpcUrls: ["https://data-seed-prebsc-1-s3.binance.org:8545"],
        chainName: "Binance Smart Test",
        nativeCurrency: { name: "tBNB", decimals: 18, symbol: "tBNB" },
        blockExplorerUrls: ["https://testnet.bscscan.com/"],
        iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"]
    },
    bsc_network: {
        chainId: "0x38",
        rpcUrls: [
            "https://bsc-dataseed1.binance.org",
            "https://bsc-dataseed1.ninicoin.io",
            "https://bsc-dataseed3.ninicoin.io",
            "https://bsc-dataseed4.ninicoin.io",
            "https://rpc.ankr.com/bsc",
            "https://binance.nodereal.io"
        ],
        chainName: "Binance Smart Chain",
        nativeCurrency: { name: "BNB", decimals: 18, symbol: "BNB" },
        blockExplorerUrls: ["https://bscscan.com/"],
        iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"]
    },
    eth_network: {
        chainId: "0x1",
        rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
        chainName: "Ethereum Mainnet",
        nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
        blockExplorerUrls: ["https://etherscan.io"],
        iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"]
    },
    polygon_network: {
        chainId: "0x89",
        rpcUrls: ["https://polygon-rpc.com/"],
        chainName: "Polygon",
        nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
        blockExplorerUrls: ["https://polygonscan.com/"],
        iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"]
    },
}


export const mainnet = {
    chain_info: chains.bsc_network_testnet,
    chain_num: 97
}